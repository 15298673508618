@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
  @apply bg-gradient-to-br from-background to-white min-h-screen font-sans;
}

.nav-link {
  @apply text-primary hover:text-secondary transition-colors duration-200;
}

.btn-primary {
  @apply bg-primary text-white px-6 py-3 rounded-full hover:bg-opacity-90 transition-all duration-200 inline-block;
}
.backimg{
  background-image: url(/public/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.logo{
  width: 30%;
}
.textcolori{
  color: #337AB7 !important;
  font-weight: 300 !important;
}
.colorfirst{
  color: #282C4B !important;
}
.spantexti{
  color: #8DA399 !important;
}
.colori{
  color: #2C2C2C !important ;
  font-weight: 500 !important;
}
.textbig{
  color: #9378B8 !important;
}
.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  gap: 20px;
}

.about-us-image {
  flex: 1;
  position: relative;
}

.therapy-image {
  width: 100%;
  height: auto;

}

.about-us-text {
  font-family: 'Lato';
  flex: 1;
  color: #8DA399;
  text-align: center;
}

.about-us-text h2 {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 20px;
  color: #9378B8;
}

.about-us-text p {
  font-weight:300 ;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 20px;
}

.session-button {
  padding: 10px 20px;
  background-color: #c4dcd2;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.session-button:hover {
  background-color: #a1c2b6;
}
@media (max-width: 768px) {
  .about-us-content {
    flex-direction: column;
    text-align: center;
  }

  .about-us-image,
  .about-us-text {
    flex: unset;
  }

  .about-us-text h2 {
    font-size: 36px;
  }

  .about-us-text p {
    font-size: 18px;
    line-height: 28px;
  }
}